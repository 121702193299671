var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.modalTitle,"size":"lg","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.addObject.returnable_packaging_items.length ===0 || _vm.showLoader},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}},[_c('b-overlay',{attrs:{"show":_vm.showLoader},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('validation-observer',{ref:"validation"},[_c('validation-provider',{attrs:{"name":_vm.$t('material.delivery_notes.pick_up_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"locale":_vm.currentLocale,"max-date":new Date(),"first-day-of-week":2,"masks":{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'},"model-config":_vm.modelConfig,"is24hr":"","hide-time-header":"","mode":"date","is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":_vm.formatDate(inputValue)}},inputEvents))]}}],null,true),model:{value:(_vm.addObject.timestamp),callback:function ($$v) {_vm.$set(_vm.addObject, "timestamp", $$v)},expression:"addObject.timestamp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('form_data.packaging')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.add_ingredient'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","filter":_vm.fuseSearch,"appendToBody":"","options":_vm.newPackagingFiltered},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var packaging_type_code = ref.packaging_type_code;
return [_c('div',[_vm._v(_vm._s(packaging_type_code)+" - "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var packaging_type_code = ref.packaging_type_code;
return [_c('div',[_vm._v(_vm._s(packaging_type_code)+" - "+_vm._s(name))])]}}],null,true),model:{value:(_vm.selected_packaging),callback:function ($$v) {_vm.selected_packaging=$$v},expression:"selected_packaging"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":_vm.$t('form_data.quantity')}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":_vm.$t('form_data.quantity'),"rules":"required|min_number: 0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.selected_quantity),callback:function ($$v) {_vm.selected_quantity=$$v},expression:"selected_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('div',{staticClass:"d-flex justify-content-center "},[_c('b-button',{staticClass:"w-25",attrs:{"variant":"primary"},on:{"click":_vm.addPackaging}},[_c('feather-icon',{staticClass:" justify-content-center",attrs:{"icon":"ArrowDownIcon"}})],1)],1),(_vm.modalActive && _vm.arraysLoad && _vm.addObject.returnable_packaging_items)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('form_data.packaging')))]),_c('CustomTable',{ref:"packagingTable",staticClass:"mt-1",attrs:{"fields":_vm.columnDefs,"items":_vm.addObject.returnable_packaging_items,"show-table-options":false},scopedSlots:_vm._u([{key:"packaging_type_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.packaging_type_code)+" ")]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity / 100)+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1 m-0 button",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removePackaging(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")])],1)]}}],null,false,3890381854)})],1):_vm._e()],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }