<template>

    <b-modal :title="modalTitle" v-model="modalActive" size="lg" no-close-on-backdrop>
        <b-overlay :show="showLoader">

            <template #default>
                <validation-observer ref="validation">
                    <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.pick_up_date')" rules="required">
                        <date-picker :locale="currentLocale" :max-date="new Date()" :first-day-of-week="2" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header mode="date" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="formatDate(inputValue)"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                    <b-row class="mt-1">
                        <b-col cols="6">
                            <b-form-group :label="$t('form_data.packaging')" class="w-100">
                                <validation-provider #default="{ errors }"
                                                     :name="$t('general.add_ingredient')" rules="required">
                                    <v-select label="name" :filter="fuseSearch" v-model="selected_packaging"
                                              appendToBody :options="newPackagingFiltered">
                                        <template #option="{ name, packaging_type_code }">
                                            <div >{{ packaging_type_code }} - {{ name }}</div>
                                        </template>

                                        <template #selected-option="{ name, packaging_type_code }">
                                            <div>{{ packaging_type_code }} - {{ name }}</div>
                                        </template>
                                    </v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">

                            <b-form-group :label="$t('form_data.quantity')" class="w-100">
                                <div style="display: flex; width: 100%">
                                    <validation-provider style="width: 100%" #default="{ errors }"
                                                         :name="$t('form_data.quantity')" rules="required|min_number: 0">
                                        <b-form-input v-model="selected_quantity"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <div class="d-flex justify-content-center ">
                        <b-button variant="primary" @click="addPackaging" class="w-25">
                            <feather-icon icon="ArrowDownIcon" class=" justify-content-center"/>
                        </b-button>
                    </div>

                    <div v-if="modalActive && arraysLoad && addObject.returnable_packaging_items">
                        <div>{{$t('form_data.packaging')}}</div>
                        <CustomTable  ref="packagingTable" class="mt-1" :fields="columnDefs" :items="addObject.returnable_packaging_items" :show-table-options="false">
                            <template #packaging_type_code="{item}">
                                {{item.packaging_type_code}}
                            </template>
                            <template #name="{item}">
                                {{item.name}}
                            </template>
                            <template #quantity="{item}">
                                {{item.quantity / 100}} 
                            </template>
                            <template #actions="{item}">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="removePackaging(item.id)">
                                        {{ $t('general.delete') }}
                                    </b-button>

                                </div>
                            </template>
                        </CustomTable>
                    </div>
                </validation-observer>
            </template>
        </b-overlay>


        <template #modal-footer>
            <b-button variant="primary" @click="validate" :disabled="addObject.returnable_packaging_items.length ===0 || showLoader">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton,  BFormGroup, BFormInput,  BModal, BRow, BCol, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'
    import {Tiers} from '@/libs/enums/Tiers'
    import {DatePicker} from 'v-calendar'
    import Fuse from 'fuse.js'
    import CustomTable from '@/components/CustomTable.vue'


    export default {
        components: {
            CustomTable,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            DatePicker,
            BRow,
            BCol,
            BOverlay

        },
        props: {
            productsRaw: {
                type: Array
            },
            vats:{
                type:Array
            }
        },
        data() {
            return {
                showLoader:false,
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    timestamp:'',
                    returnable_packaging_items:[]
                },
                selected_packaging:null,
                selected_quantity:0,
                allPackagingTypes:[],
                newPackagingFiltered:[],
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },

                required,
                editing: false
            }
        },
        computed:{
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            modalTitle() {
                return this.editing ? this.$t('keys.returnable_packaging.edit_own_collection') : this.$t('keys.returnable_packaging.add_own_collection')
            },
            columnDefs() {

                const hasPermission =  this.$hasPermission(this.$permissions.ReturnablePackagingWrite)
                const defs = [
                    {
                        key: 'packaging_type_code',
                        label: this.$t('table_fields.code'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'name',
                        label: this.$t('form_data.packaging'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'quantity',
                        label:  this.$t('table_fields.quantity'),
                        filter: false,
                        permissions: true
                    }
                ]
                if (hasPermission) {
                    defs.push(
                        {
                            key: 'actions',
                            label: this.$t('table_fields.actions'),
                            permissions: true
                        }
                    )
                }

                return defs
            }
        },
        methods: {
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name', 'packaging_type_code'],
                    shouldSort: true
                })
                return search.length ? fuse.search(search).map(({item}) => item) : fuse.list
            },
            formatDate(inputDate) {
                return this.dayjs(inputDate).format('DD.MM.YYYY')
            },
            getPackagingName(params) {
                if (params && this.allPackagingTypes && this.allPackagingTypes.length > 0) {
                    const tmp = this.allPackagingTypes.find(x => x.id === params.id)

                    if (tmp) return tmp.name
                }
                return '/'
            },
            getPackagingCode(params) {

                if (params && this.allPackagingTypes && this.allPackagingTypes.length > 0) {
                    const tmp = this.allPackagingTypes.find(x => x.id === params.id)

                    if (tmp) return tmp.packaging_type_code
                }

                return '/'
            },
            async addPackaging() {
                try {
                    const success = await this.$refs.validation.validate()

                    let tmp = this.selected_quantity

                    if (tmp) {
                        tmp = String(tmp).replace(',', '.')
                    }

                    if (success) {
                        const packaging = {
                            'returnable_packaging_type_id': this.selected_packaging.id,
                            'name': this.selected_packaging.name,
                            'packaging_type_code': this.selected_packaging.packaging_type_code,
                            'quantity': Number(tmp) * 100
                        }

                        this.addObject.returnable_packaging_items.push(packaging)
                        this.newPackagingFiltered = this.newPackagingFiltered.filter(ele => ele.id !== this.selected_packaging.id)

                        this.selected_packaging = null
                        this.selected_quantity = ''
                        this.$refs.validation.reset()
                    }
                } catch (err) {
                    this.$printError(this.$t('print.error.on_add'))
                }


            },
            open(data = null) {
                this.loadReturnablePackagingTypes()
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        timestamp: new Date(),
                        returnable_packaging_items: []
                    }
                }
                this.modalActive = true
            },
            validate() {
                if (this.editing) this.edit()
                else this.add()
            },
            async add() {
                try {
                    this.showLoader = true
                    const response = await this.$http.put('/api/client/v1/returnable_packaging/own_collection', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        timestamp:'',
                        returnable_packaging_items: []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit() {
                try {
                    this.showLoader = true

                    await this.$http.put(`/api/client/v1/returnable_packaging/own_collection/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        timestamp:'',
                        returnable_packaging_items: []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false

                }
            },
            removePackaging(id) {
                for (let i = 0; i < this.addObject.returnable_packaging_items.length; i++) {
                    if (this.addObject.returnable_packaging_items[i].returnable_packaging_type_id === id) {
                        this.newPackagingFiltered.push(this.allPackagingTypes.filter((ingredient) => {
                            return ingredient.id === id
                        })[0])
                        this.addObject.returnable_packaging_items.splice(i, 1)
                        break
                    }
                }
            },
            async loadReturnablePackagingTypes() {

                if (!this.$hasTierPermission(Tiers.Premium)) {
                    return
                }

                try {
                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/returnable_packaging/type')
                    this.allPackagingTypes = response.data.items ?? []
                    this.newPackagingFiltered = this.allPackagingTypes.filter((ingredient) => {
                        return !this.addObject.returnable_packaging_items.some((addedIngredient) => addedIngredient.returnable_packaging_type_id === ingredient.id)
                    })

                    this.arraysLoad = true

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.productsModal .modal-dialog .modal-content {
  max-height: 90vh !important;
  height: 90vh !important;
}
</style>